.show-policy {
    display: none;
}

.show-policy a {
    color: #000;
}

.show-policy.visible {
    display: block;
    position: fixed;
    top: 105px;
    width: calc(100% - 30px);;
    height: 76%;
    background: #fff;
    color: #000;
    padding-left: 10px;
    padding-top: 10px;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid;
    left: 8px;
    padding-bottom: 5px;
}

.close {
    position: absolute;
    top: 8px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
}