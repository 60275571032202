.CookieConsent a.show {
    color: #fff;
    display: block;
    margin-top: 20px;
}

@media screen and (min-width: 780px) {
    .CookieConsent a.show {
        display: inline !important;
        margin-left: 20px;
    }
}