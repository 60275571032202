@font-face {
  font-family: "Font Gilroy Regular";
  src: url('Font Gilroy/Gilroy-Regular.otf');
}

@font-face {
  font-family: "Font Gilroy Medium";
  src: url('Font Gilroy/Gilroy-Medium.otf');
}

@font-face {
  font-family: "Font Gilroy Bold";
  src: url('Font Gilroy/Gilroy-Bold.otf');
}

@font-face {
  font-family: "Font Gilroy Extrabold";
  src: url('Font Gilroy/Gilroy-ExtraBold.otf');
}

@font-face {
  font-family: "Font Gilroy Semibold";
  src: url('Font Gilroy/Gilroy-SemiBold.otf');
}

html, body {
  padding: 0;
  margin: 0;
  font-family: "Font Gilroy Regular";
}

input:focus {
  outline: none;
}

input {
  font-family: "Font Gilroy Regular";
  font-size: 16px;
  color: #182243;
}

p {
  font-size: 16px;
  line-height: 27px;
  max-width: 400px;
  color: #182243;
}

h1, h2, h3, h4, h5, h6, span {
  color: #182243;
}

.bold {
  font-family: "Font Gilroy Bold";
}

.medium {
  font-family: "Font Gilroy Medium";
}

.semi-bold {
  font-family: "Font Gilroy Semibold";
}

.App {
  max-width: 1920px !important;
  margin: auto;
  overflow: hidden;
}

.bgColor {
  width: 100%;
  background: #FAFBFC;
  display: block;
  position: absolute;
  padding-top: 51%;
  left: 0;
  z-index: 1;
  margin-top: -15px;
}

.section-about-wrapper {
  position: relative;
  z-index: 1;
}

section, header {
  max-width: 1920px;
}

#root {
  max-width: 1920px;
  margin: auto;
}

.landing-section {
  /* min-height: 100vh; */
  padding-bottom: 30px;
  padding: 0 23px;
  width: 100%;
  max-width: calc(100% - 26px);
}

.hero-section {
  align-items: center;
  text-align: center;
  min-height: auto;
}

.header-take-survey {
  display: none !important;
}

.header-take-survey,
.section-take-survey {
  cursor: pointer;
}

.section-take-survey {
  /* margin-bottom: 50px; */
  margin-top: 50px;
}

.get-the-app.get-the-app-mobile {
  box-shadow: #ccc 2px 14px 25px;
}

.slider-mobile .slick-list {
  padding-bottom: 23px;
  margin-bottom: -23px;
}

header {
  position: fixed;
  background-color: #FFF4F4;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  padding-top: 22px;
  z-index: 2;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px !important;
  transition: all .5s ease;
}

.hamburger-menu {
  cursor: pointer;
}

.sidenav.open {
  width: 325px;
}

.hamburger-menu div {
  width: 18px;
  height: 2px;
  background-color: black;
  margin: 6px 0;
}

.hero-section {
  background-color: #FFF4F4;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 80px !important;
}

.logo img {
  height: 35px;
}

.btn {
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #FF5D5E;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.hero-images {
  position: relative;
  height: 297px;
  max-width: 500px;
  width: 100vw;
  overflow: hidden;
  align-self: baseline;
  margin-left: auto;
}

.img-hero {
  position: absolute;
}

.img-1 {
  top: -3%;
  right: 4%;
  transform: rotate(-54deg);
  height: 200px;
}

.img-2 {
  top: 61%;
  height: 42px;
  left: 3%;
}

.img-3 {
  height: 500px;
  top: 31%;
  left: 13%;
}

.h50 {
  height: 50%;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.main-section-img {
  height: 338px;
  align-self: center;
}

.full-control {
  background-color: #9B75F0;
}

.we-use-magic {
  background-color: #0E9BF0;
}

.first-class-nutrition {
  background-color: #BB66D4;
}

.about-dr-badi {
  background-color: #EB5051;
  margin-bottom: 60px;
  padding-bottom: 30px;
  border-radius: 30px;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 40px;
}

.about-dr-badi .btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.hero-title {
  font-size: 38px;
  max-width: 836px;
  position: relative;
  margin-top: 38px;
  margin-bottom: 0;
  font-family: "Font Gilroy Extrabold";
}

.highlight-text {
  position: absolute;
  left: 195px;
  top: -31px;
  height: 50px;
}

/* .card-how-it-works .slick-initialized .slick-slide {
  margin-right: 15px;
} */

.slider-mobile {
  max-height: 350px;
}

.text-emphasize {
  color: #FF5D5E;
}

.join-us {
  margin-top: 51px;
  font-size: 20px;
  font-family: "Font Gilroy Bold";
}

.join-us-icons img {
  height: 32px;
}

.join-us-heading {
  margin-bottom: 20px;
  display: block;
}

.join-us-icons {
  max-width: 143px;
  display: flex;
  justify-content: space-between;
}

.join-us-icons a {
  padding: 15px;
  background-color: #F8E5E5;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-section:not(.hero-section) {
  padding-top: 50px;
}

.center-text {
  text-align: center;
}

.section-heading {
  font-family: "Font Gilroy Extrabold";
  font-size: 30px;
  margin-bottom: 0;
  line-height: 42px;
}

section.color-white, .color-white p, .color-white span, .color-white h1,
.color-white h2, .color-white h3, .color-white h4, .color-white h5, .color-white h6 {
  color: #fff !important;
}

.main-section p:last-child {
  padding-bottom: 15px;
}

.main {
  line-height: 34px;
}

.w100 {
  width: 100%;
}

.btn-white {
  background-color: rgba(255,255,255, .8);
  color: #FF5D5E;
  margin-right: auto;
}

footer {
  padding: 30px 20px;
  background-color: #182243;
  color: #fff;
}

a.anchor {
  position: relative;
  display: block;
  top: -120px;
  visibility: hidden;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.footer-menu {
  margin-top: 20px;
}

.bottom-menu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.footer-menu a {
  color: inherit;
  text-decoration: none;
}

.footer-menu li {
  margin-bottom: 23px;
}

.email-field {
  background: #fff;
  display: flex;
  padding: 3px;
  border-radius: 12px;
  max-width: 400px;
}

.email-input {
  background: transparent;
  border: none;
  width: 75%;
  padding: 8px;
  margin-right: 1%;
}

.newsletter {
  margin-top: 40px;
}

.newsletter p {
  color: #fff;
}

.divider {
  border-bottom: 1px solid rgba(255,255,255,.8);
  margin-top: 40px;
}

.footer-social-icons {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  /* justify-content: space-between; */
  margin-left: auto;
  margin-right: auto;
  max-width: 358px;
}

.footer-social-icons a {
  margin-right: 32px;
}

.footer-social-icons a:first-child {
  margin-left: 8px;
}

.footer-social-icons img {
  padding: 15px;
  background-color: #FF5D5E;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.read-more {
  background-color: #fff;
  font-family: "Font Gilroy Semibold";
  max-width: 103px;
}

.cards-wrpapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 60px;
}

.card-header {
  font-family: "Font Gilroy Extrabold";
  margin-bottom: 0;
  width: 85%;
  text-align: center;
  line-height: 25.2px;
}

.card-info {
  font-size: 14px;
  width: 94%;
  line-height: 22.4px;
  text-align: center;
  font-family: "Font Gilroy Medium";
}

.card {
  border: 1px solid #EDEEF0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 2px;
  margin-right: 10px;
  max-width: 290px;
}

.get_the_app {
  background-color: #A37EF5;
  padding: 20px;
  border-radius: 18px;
}

.hassle_free {
  background-color: #C36CDC;
  padding: 20px;
  border-radius: 18px;
}

.weight_program {
  background-color: #8EC332;
  padding: 20px;
  border-radius: 18px;
}

.starter_kit {
  background-color: #FF5D5E;
  padding: 20px;
  border-radius: 18px;
}

.obtain_peronalized {
  background-color: #03A0FF;
  padding: 20px;
  border-radius: 18px;
}

.difference {
  height: 229px;
  border: 0;
  /* max-width: initial; */
  max-width: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  justify-content: end;
  padding: 0px 0px !important;
}

.difference .card-info {
  margin-right: 20px;
  width: 80%;
  color: #fff;
  text-align: left;
}

.difference-decades {
  background-image: url("./What makes us different/Satisfied-customers.webp");
}

.difference-personalised {
  background-image: url("./What makes us different/programme-for-healthy-and-chronically-ill-adults-and-children.webp");
}

.difference-scientifically {
  background-image: url("./What makes us different/innovative-patented-products-backed-by- scientific-research.webp");
}

.difference-guarantee {
  background-image: url("./What makes us different/money-back-guarantee.webp");
}

.difference-wrapper {
  padding-bottom: 36px;
  margin-bottom: 17px;
}

.slider-desktop {
  display: none;
}

.slider-mobile .cards-wrpapper {
  display: block;
}

.about {
  min-height: auto;
  overflow: hidden;
  position: relative;
}

.footer-logo {
  height: 78px;
  margin-bottom: 20px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed; 
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #FFF;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

.sidenav.open {
  width: 325px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #182243;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.lang-menu {
  transition: all .5s ease;
  max-height: 0;
  overflow: hidden;
}

.lang-menu.open {
  max-height: 150px;
}

.lang-menu li {
  padding: 8px 8px 8px 52px;
}

.globe {
  margin-right: 5px;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 24px;
  border: 2px solid transparent;
  border-radius: 100px
}

.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px
}

.gg-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 16px;
  border: 2px solid transparent;
  border-radius: 100px
}

.gg-chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(225deg);
  left: 4px;
  top: 2px
}

.sidenav .closebtn {
  position: absolute;
  top: 47px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.telegram-icon-header {
  margin-right: 10px;
}

#main {
  transition: margin-left .5s;
  padding: 20px;
}

.sidenav a {
  font-size: 18px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
}

@media (min-width: 514px) {
  .hero-images {
    margin-right: -9px;
  }
}

@media (min-width: 810px) and (max-width: 1300px) {
  .landing-section:not(.hero-section) {
    align-items: center;
  }
  .landing-section:not(.hero-section) main {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .landing-section:not(.hero-section) .section-heading {
    text-align: center;
    max-width: 80%;
    padding-bottom: 20px;
  }
}

@media (max-width:1300px) {
  .footer-menu:nth-child(2) {
    margin-top: 0;
  }
}

@media (min-width: 1300px) {
  .card-how-it-works .card:hover {
    box-shadow: #ccc 2px 2px 25px;
  }

  p {
    font-size: 20px;
    line-height: 34px;
  }
  
  .card-how-it-works .card {
    padding-top: 30px;
  }

  .section-heading {
    font-size: 48px;
    line-height: 67.2px;
  }

  .how-it-works-title {
    padding-bottom: 80px;
  }

  .hero-title {
    font-size: 72px;
  }

  .landing-section:not(.hero-section) {
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
  }

  .about-dr-badi {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 50px !important;
    padding-right: 80px !important;
  }

  .about-dr-badi main {
    max-width: 51% !important;
  }

  .about-dr-badi .main-section-img {
    height: 485px;
  }

  a#how + section {
    padding-bottom: 130px;
  }

  .section-about-wrapper {
    margin-top: 100px;
  }

  .what-makes-title {
    margin-bottom: 80px;
  }

  .join-us {
    max-height: 100px;
    padding-top: 41px;
  }

  header.header {
    padding: 33px 120px 15px 120px;
  }

  .logo img {
    height: 70px;
  }

  .landing-section {
    padding: 0 100px;
    max-width: calc(100% - 158px);
  }

  p {
    max-width: 640px;
  }

  .hero-images {
    overflow: initial;
    max-width: 40%;
    margin-top: -84px;
  }

  .hero-section {
    align-items: baseline;
    text-align: left !important;
    overflow: hidden;
  }

  .section-take-survey {
    display: none;
  }

  .join-us {
    margin-top: 1px !important;
  }
  
  .difference {
    height: 332px;
    max-width: initial !important;
    width: 23%;
    /* max-width: initial; */
  }

  .header-take-survey {
    display: block !important;
  }

  .img-1 {
    top: -156%;
    right: 20%;
    transform: rotate(-54deg);
    height: 200px;
  }

  .img-2 {
    top: 6%;
    height: 89px;
    left: -44%;
  }

  .img-3 {
    height: 1047px;
    top: -118%;
    left: -18%;
  }

  .card-how-it-works .card {
    height: 274px;
    width: 224px;
  }

  .card-how-it-works {
    max-width: 1600px;
  }

  .main-section {
    flex-direction: row;
    justify-content: space-around;
  }

  .main-section {
    min-height: auto;
    padding-bottom: 40px;
  }

  .main-section p {
    /* max-width: 70%; */
  }

  .main-section-img {
    align-self: initial;
    height: 538px;
  }

  .main-section main {
    max-width: 41%;
  }

  .full-control img,
  .we-use-magic img, 
  .first-class-nutrition img {
    order: 2;
  }

  .about-dr-badi {
    width: 80%;
    margin: auto;
  }

  .about-dr-badi p {
    max-width: initial;
  }

  .read-more {
    background-color: #fff;
    width: 91px;
  }

  footer {
    padding: 50px 120px;
  }

  footer .upper-part {
    display: flex;
    justify-content: space-between;
  }

  .newsletter {
    margin-top: 0;
  }

  .about-dr-badi {
    margin-bottom: 90px;
  }

  .whatsapp-icon-header:hover {
    background-color: #fff;
  }
  
  .telegram-icon-header:hover {
    background-color: #0E9BF0;;
  }

  .hero-section {
    background-color: #FFF4F4;
    padding-top: 159px !important;
  }

  .slider-desktop {
    display: block;
  }

  .slider-mobile {
    display: none;
  }

  header.scrolled {
    padding-top: 20px;
  }

  .hamburger-menu {
    display: none;
  }

  .sidenav {
    width: auto;
    display: flex;
    background-color: #FFF;
    overflow-x: hidden;
    position: relative;
    padding-top: initial;
    background: transparent;
    position: relative;
    overflow: visible;
  }

  .lang-menu {
    position: absolute;
    right: -8px;
    margin-top: 33px;
    background-color: #fff;
    /* display: flex; */
    border-radius: 5px;
    box-shadow: #ccc 8px 3px 21px;
  }

  .lang-menu li {
    padding: 8px 36px 8px 36px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }

  .lang-menu li:last-child {
    border: none;
  }

  .sidenav .closebtn {
    display: none;
  }

  .bottom-menu {
    width: 26%;
    flex-direction: row;
  }
}